import * as React from 'react';
import '../../styles/global.scss';
import '../../styles/normalize.scss';
import '../../styles/typography.scss';
import '../../styles/settings.scss';
import RootLayout from '../../components/RootLayout/RootLayout';
import Headers from '../../components/Headers/Headers';
import Footer from '../../components/Footer/Footer';
import ScroolUpButton from '../../components/ScroolUpButton/ScroolUpButton';
import BlogArticleWebAppsVsWebsites from '../../components/BlogArticles/BlogArticleWebAppsVsWebsites ';

const BlogWebAppVsWebSite = () => {
  const title_top = 'Web Applications';
  const title_middle = ' vs.';
  const title_bottom = 'Websites';
  const siteTitle = 'Web Applications vs. Websites – When to Choose an Application? - Nowoczesna witryna';
  const siteDescription =
    'The choice between a web application and a website depends on a business’s needs and user expectations. While both run in a browser, they differ in functionality, scalability, and range of use. In this article, we explore the key differences between web applications and websites, as well as scenarios where choosing a web application makes sense.';
  const siteUrl = 'https://www.nowoczesnawitryna.com/blog/web-applications-vs-websites/';
  const siteUrlOtherLang = 'https://www.nowoczesnawitryna.pl/blog/aplikacje-webowe-vs-strony-internetowe/';

  return (
    <>
      <RootLayout title={siteTitle} url={siteUrl} urlOtherLang={siteUrlOtherLang} description={siteDescription} />
      <Headers title_top={title_top} title_middle={title_middle} title_bottom={title_bottom} active="blog" />
      <ScroolUpButton />
      <BlogArticleWebAppsVsWebsites />
      <Footer />
    </>
  );
};

export default BlogWebAppVsWebSite;
